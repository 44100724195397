fetch('/graphql', {
  headers: { accept: '*/*', 'content-type': 'application/json' },
  body: JSON.stringify({
    operationName: 'loginAppConfig',
    query:
      `query loginAppConfig {
        loginAppConfig {
          apolloClientURL
          absoluteUrlString
          meteorPublic
          isDevelopment
        }
      }`,
  }),
  method: 'POST',
  // "mode": "cors",
  // "credentials": "omit"
}).then((r) => r.json()).then((r) => {
  const { apolloClientURL, absoluteUrlString, meteorPublic, isDevelopment } = r.data.loginAppConfig;
  window.apolloClientURL = apolloClientURL;
  window.absoluteUrlString = absoluteUrlString;
  window.meteorPublic = JSON.parse(meteorPublic);
  window.isDevelopment = isDevelopment;
}).then(() => {
  import('./indexAfterConfigLoaded');
});
